<template>
  <div class="templateClass">
    <div class="templateCentreContent">
      <el-card class="box-card">
        <div slot="header" style="position: relative">
          <div class="query">
            <el-input
              class="input-field"
              style="width: 250px"
              clearable
              placeholder="请输入标题"
              v-model="queryData.title"
              @keyup.enter.native="getDataList(true)"
            ></el-input>

            <el-button type="success" class="ml10" @click="getDataList(true)">查询</el-button>

            <el-button class="op_btn" type="primary" @click="addOrEditPopup()">新增话术</el-button>
          </div>
        </div>

        <!-- PC端展示 -->
        <el-table
          class="pc-table"
          :data="listData"
          v-loading="loading"
          element-loading-text="正在拼命加载中"
          element-loading-spinner="el-icon-loading"
          border
          stripe
        >
          <el-table-column type="index" :index="1" label="#" width="50"></el-table-column>
          <el-table-column prop="title" label="标题" min-width="120"></el-table-column>
          <el-table-column prop="detectionItem" label="检测项目" min-width="120"></el-table-column>
          <el-table-column prop="isEnable" label="是否启用" min-width="120">
            <template slot-scope="scope">
              <span :style="scope.row.isEnable == 1 ? 'color:#67C23A' : 'color:#F56C6C'">
                {{ scope.row.isEnable == 1 ? "启用" : "禁用" }}
              </span>
            </template>
          </el-table-column>
          <el-table-column prop="startScope" label="开始范围" min-width="120"></el-table-column>
          <el-table-column prop="endScope" label="结束范围" min-width="120"></el-table-column>
          <el-table-column prop="startAge" label="开始年龄" min-width="120"></el-table-column>
          <el-table-column prop="endAge" label="结束年龄" min-width="120"></el-table-column>
          <el-table-column prop="level" label="优先级" min-width="120"></el-table-column>
          <el-table-column label="创建时间" min-width="160">
            <template slot-scope="scope">
              <p>{{ scope.row.createTime | dateFormat1 }}</p>
            </template>
          </el-table-column>
          <el-table-column fixed="right" prop="operation" label="操作" width="180px;">
            <template slot-scope="scope">
              <div class="tableOperation">
                <el-button type="text" @click="addOrEditPopup(scope.row.id)">编辑</el-button>
                <span class="operationLine">|</span>
                <el-button class="F56C6C" type="text" @click="del(scope.row.id)">删除</el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>

        <!-- 移动端展示 @scroll="handleScroll"-->
        <div class="mobile-cards" >
          <el-card
            v-for="(item, index) in listData"
            :key="index"
            class="talk-card"
            shadow="hover"
          >
            <div class="card-content">
              <div class="card-header">
                <!-- <el-dropdown>
                  <el-button type="text" class="el-dropdown-link">
                    操作<i class="el-icon-arrow-down el-icon--right"></i>
                  </el-button>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item @click.native="addOrEditPopup(item.id)">编辑</el-dropdown-item>
                    <el-dropdown-item @click.native="del(item.id)">删除</el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown> -->
              </div>
              <div class="card-item">
                <div class="flex align_center pb10 mb10 br_bottom pl10 pr10">
                  <p class="item_seq">{{ getSeq(index, queryData.current, queryData.size) }}</p>
                  <p class="ml10 mr10">{{ item.title }}</p>
                  <p  class=" flex1 t_r" ><span :style="item.isEnable == 1 ? 'color:#67C23A' : 'color:#F56C6C'">{{ item.isEnable == 1 ? "启用" : "禁用" }}</span></p>
                </div>
                <div class="pl10 pr10 pb10 mb10">
                  <p class="mb10">检测项目: {{ item.detectionItem }}</p>
                  <p class="mb10">开始范围: {{ item.startScope }}</p>
                  <p class="mb10">结束范围: {{ item.endScope }}</p>
                  <p class="mb10">开始年龄: {{ item.startAge }}</p>
                  <p class="mb10">结束年龄: {{ item.endAge }}</p>
                  <p class="mb10">优先级: {{ item.level }}</p>
                  <p class="mb10">创建时间: {{ item.createTime | dateFormat1 }}</p>
                </div>
              </div>
            </div>
          </el-card>
          <div v-if="loading" class="loading-indicator">加载中...</div>
        </div>

        <el-pagination
          class="floatRight"
          v-if="total > 10"
          @size-change="getDataListSizeChange"
          @current-change="getDataListCurrentChange"
          :current-page.sync="queryData.current"
          :page-sizes="[10, 20, 30, 40]"
          :page-size="queryData.size"
          :total="total"
          layout="total,sizes, prev, pager, next"
        ></el-pagination>
      </el-card>
    </div>
    <add-or-edit ref="addOrEdit" @refreshDataList="getDataList()"></add-or-edit>
  </div>
</template>

<script>
import AddOrEdit from "./talk-add-or-edit";
export default {
  components: {
    AddOrEdit,
  },
  data() {
    return {
      loading: false,
      listData: [],
      total: 0,
      queryData: {
        size: 10,
        current: 1,
        title: '',
      },
      assignRolesFrom: {},
      assignRolesFromRule: {
        roleIdList: [
          { required: true, message: "该项为必选项", trigger: "change" },
        ],
        userId: [{ required: true, message: "该项为必填项", trigger: "blur" }],
      },
      assignRolesVisible: false,
      roleAllList: [],
    };
  },
  created() {
    this.getDataList();
    this.getRoleAllList();
  },
  methods: {
    /**
     * 获取所有角色
     *  */
    getRoleAllList() {
      this.$http.get(`admin/sys-role/list`).then((res) => {
        if (res.data.code == 200) {
          let data = res.data.data;
          if (data) this.roleAllList = data;
        }
      });
    },
    /**
     * 给用户分配角色
     */
    assignRolesPopup(userId, roleIdList) {
      this.assignRolesVisible = true;
      this.assignRolesFrom = {};
      this.assignRolesFrom.userId = userId;
      if (roleIdList) this.$set(this.assignRolesFrom, "roleIdList", roleIdList);
    },
    assignRoles() {
      this.$refs["assignRolesFrom"].validate((valid) => {
        if (valid) {
          this.$http
            .post(
              `admin/sys-user-role/saveOrUpdateSysUserRole`,
              this.assignRolesFrom
            )
            .then((res) => {
              if (res.data.code == 200) {
                this.getDataList();
                this.assignRolesVisible = false;
              } else {
                this.$message({
                  message: res.data.msg,
                  type: "warning",
                });
              }
            });
        }
      });
    },
    /**
     * 新增或修改弹窗
     */
    addOrEditPopup(id) {
      this.$nextTick(() => {
        this.$refs.addOrEdit.init(id);
      });
    },
    /**
     * 删除数据
     */
    del(id) {
      if (id) {
        this.$confirm("是否删除所选择的数据?", "温馨提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
      let loginUserInfo = this.$store.state.loginUserInfo;
            const resData = {
              id,
              customerId: loginUserInfo.isAdmin ? 'ALL' : (loginUserInfo.userId || null),
            }
            this.$http
              .post(`/dbVerbalTrick/delVerbalTrick`, resData)
              .then((res) => {
                if (res.data.code == 200) {
                  this.getDataList();
                  this.$message({
                    type: "success",
                    message: "删除成功!",
                  });
                } else {
                  this.$message({
                    message: res.data.msg,
                    type: "warning",
                  });
                }
              });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消删除",
            });
          });
      }
    },
    /**
     * 加载列表数据数据
     * @flag {boolean} 是否设置current为1
     */
    getDataList(flag) {
      if (flag) this.queryData.current = 1;
      this.loading = true;
      this.$http
        .post(`dbVerbalTrick/findVerbalTrickPage`, this.queryData)
        .then((res) => {
          if (res.data.code === 200) {
            let data = res.data.data;
            if (data) {
              this.listData = data.records;
              this.total = data.total;
            }
            this.loading = false;
          }
        });
    },
    // handleScroll(event) {
    //   const bottomOfWindow =
    //     event.target.scrollHeight - event.target.scrollTop ===
    //     event.target.clientHeight;
    //   if (bottomOfWindow && !this.loading) {
    //     this.queryData.current += 1;
    //     this.getDataList();
    //   }
    // },
    getDataListSizeChange(val) {
      this.queryData.size = val;
      this.getDataList(true);
    },
    getDataListCurrentChange(val) {
      this.queryData.current = val;
      this.getDataList();
    },
  },
};
</script>
<style scoped>
.templateClass {
  padding: 10px;
}

.pc-table {
  display: block;
}

.mobile-cards {
  display: none;
}

.talk-card {
  margin-bottom: 20px;
}

.card-content {
  display: flex;
  flex-direction: column;
}

.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.card-body {
  font-size: 14px;
  color: #666;
}

.el-card {
  border-radius: 8px;
  overflow: hidden;
}

.el-button {
  margin-left: 5px;
}

/* 媒体查询 */
@media (max-width: 768px) {
  .pc-table {
    display: none;
  }
  .mobile-cards {
    display: block;
  }
  .input-field {
    flex: 1 !important;
  }
}
</style>